#HUD {
    background-color: #1a1a1a;
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    font-size: 1vw;
}

#TopBar {
    background-color: #0f0f0f;
    padding: 1em 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    font-size: 0.8em;
}

#NameLogoLink {
    width: 10%;
}

#NameLogo {
    width: 100%;
}

.subpagebutton {
    background-color: #2e49e3;
    color: white;
    padding: 0.2em 1em;
    text-align: center;
    cursor: pointer;
    border-radius: 2.5em;
    border: 0.3em solid #545454;
}

.subpagebutton:hover {
    background-color: #1e2eba;
}

#Main {
    background-color: #1a1a1a;
    display: flex;
    min-height: 100vh;
    font-size: 1.2em;
}

#Content {
    flex-grow: 1;
}

#SideBar {
    background-color: #222222;
    color: white;
    padding: 0 1em;
    width: 20%;
}

#SideBar > * {
    color: white;
}

#BottomBar {
    background-color: #222222;
    padding: 1em 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}