#Screen {
  position: relative;
  width: 80%;
  aspect-ratio: 16/9;
}

#Grid {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(9, 1fr);
  margin: 20px;
  border: 5px solid #000000;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
}

.lightBlock {
  width: 100%;
  height: 100%;
}